import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Notification from './Notification';

const NotificationsWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
  width: 360px;
  padding: 0 16px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
`

class Notifications extends React.Component {
  
  static propTypes = {
    notifications: PropTypes.array,
    onRequestHide: PropTypes.func,
    enterTimeout: PropTypes.number,
    leaveTimeout: PropTypes.number
  };

  static defaultProps = {
    notifications: [],
    onRequestHide: () => {
    },
    enterTimeout: 400,
    leaveTimeout: 400
  };

  handleRequestHide = (notification) => () => {
    const { onRequestHide } = this.props;
    if (onRequestHide) {
      onRequestHide(notification);
    }
  };

  render() {

    const { notifications, enterTimeout, leaveTimeout } = this.props;

    const items = notifications.map((notification) => {
      const key = notification.id || new Date().getTime();
      return (
        <Notification
          type={notification.type}
          title={notification.title}
          message={notification.message}
          timeOut={notification.timeOut}
          onClick={notification.onClick}
          onRequestHide={this.handleRequestHide(notification)}
        />
      );
    });

    return (
      <NotificationsWrapper>
        {items}
      </NotificationsWrapper>
    );

  }
}

export default Notifications;