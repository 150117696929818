// Запрос на восстановление пароля

import axios from './../../axiosInstance.js';

const restorePasswordRequest = (set) => async () => {

	try {

		// API POST-запрос

		const token = localStorage.getItem('token');

		const response = await axios.get('/api/account/',{
			headers: { Authorization: `Bearer ${token}` }
		});

		return response.data;

	} catch (err) {

		// Обработка ошибок - ответ на запрос не был получен

		if ( err.response === undefined ) {
			return {
				state: 'error',
				errorCode: 'NoResponseError'
			}
		}

		// Обработка ошибок - неизвестная ошибка

		if ( err.response.status === 400 && err.response.data.errorCode === 'UndefinedError' ) {
			return {
				state: 'error',
				errorCode: err.response.data.errorCode
			}
		}

		//

		console.log(err);

	}

}

export default restorePasswordRequest;