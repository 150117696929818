import { create } from 'zustand';

// Вход в систему
import login from './login.js';

 // Выход из системы
import logout from './logout.js';

// Получение сведений по сессии
import getUserSessionInfo from './getUserSessionInfo.js';

// Смена пароля
import changePassword from './changePassword.js';

// Запрос на восстановление пароля
import restorePassword from './restorePassword.js';

// Восстановление пароля
import restorePasswordRequest from './restorePasswordRequest.js';

//

const useAuthStore = create((set, get) => ({
	// Статус авторизации
	isAuthenticated: false,
	// Свойства пользователя
	userProps: {},
	userRoles: [],
	userPermissions: [],
	// Действия пользователя
	login: login(set),
	getUserSessionInfo: getUserSessionInfo(set),
	changePassword: changePassword(set),
	logout: logout(set),
	restorePasswordRequest: restorePasswordRequest(set),
	restorePassword: restorePassword(set)
}));

export default useAuthStore;