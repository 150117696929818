// Вход в систему

import axios from './../../axiosInstance.js';

const login = (set) => async ({ username, password }) => {

	try {

		// API POST-запрос

		const response = await axios.post('/api/account/login', { 
			username, password 
		});

		// Сохраняем токен авторизации в Local Storage

		localStorage.setItem('token', response.data.token);

		// Устанавливаем статус авторизации в системе

		set({
			isAuthenticated: true, 
			props: response.data.props
		});

		// Возвращаем результат выполнения

		return {
			state: 'success'
		}

	} catch (err) {

		// Обработка ошибок - ответ на запрос не был получен

		if ( err.response === undefined ) {
			return {
				state: 'error',
				errorCode: 'NoResponseError'
			}
		}

		// Обработка ошибок
		// UserNotFoundError - пользователь не найден
		// IncorrectPasswordError - пароль указан неправильно
		// UndefinedError - неизвестная ошибка

		if ( err.response.status === 400 && ['UserNotFoundError', 'IncorrectPasswordError', 'UndefinedError'].includes(err.response.data.errorCode) ) {
			return {
				state: 'error',
				errorCode: err.response.data.errorCode
			}
		}

		//

		console.log(err);

	}

}

export default login;