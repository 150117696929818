import React, { Suspense } from 'react';

import AppRouter from './AppRouter.js';

import CheckAuthWrapper from './Layout/CheckAuthWrapper.js';

import Loader from './Components/Loader/Loader.js';

import NotificationContainer from './Components/Notification/NotificationContainer.js';

function App() {

  return (
    <div className="app">
      <Suspense fallback={<Loader />}>
        <AppRouter />
      </Suspense>
      <CheckAuthWrapper />
      <NotificationContainer />
    </div>
  );

}

export default App;