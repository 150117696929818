import React, { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Layout

const ProtectedLayoutWrapper = lazy(() => import('./Layout/ProtectedLayoutWrapper.js'));

const PublicLayout = lazy(() => import('./Layout/PublicLayout.js'));

const UserLayout = lazy(() => import('./Layout/UserLayout.js'));

// Errors

const ForbiddenPage = lazy(() => import('./Pages/ForbiddenPage/ForbiddenPage.js'));

const Error404Page = lazy(() => import('./Pages/Error404Page/Error404Page.js'));

// Router

const router = createBrowserRouter([
	{
		element: <PublicLayout />,
		children: [
			{
				path: '/',
				Component: lazy(() => import('./Pages/HomePage/HomePage.js'))
			},
			{
				path: 'login',
				Component: lazy(() => import('./Pages/LoginPage/LoginPage.js'))
			},
		]
	},
	{
		element: <ProtectedLayoutWrapper Component={UserLayout} />,
		children: [
			{
				path: 'account',
				Component: lazy(() => import('./Pages/AccountPage/AccountPage.js'))
			},
			{
				path: 'calendar',
				Component: lazy(() => import('./Pages/CalendarPage/CalendarPage.js'))
			},
			{
				path: 'current',
				Component: lazy(() => import('./Pages/TodosPage/TodosPage.js'))
			},
			{
				path: 'projects',
				Component: lazy(() => import('./Pages/ProjectsPage/ProjectsPage.js'))
			},
			{
				path: 'projects/:project_id',
				Component: lazy(() => import('./Pages/ProjectPage/ProjectPage.js'))
			},
			{
				path: 'tasks',
				Component: lazy(() => import('./Pages/AllTodosPage/AllTodosPage.js'))
			},
			{
				path: 'tasks/:task_id',
				Component: lazy(() => import('./Pages/TaskPage/TaskPage.js'))
			}
		]
	},
	{
		path: '*',
		Component: lazy(() => import('./Pages/Error404Page/Error404Page.js'))
	}
]);

//

function AppRouter() {

  return <RouterProvider router={router} />;

}

export default AppRouter;