// Получение данных по сессии пользователя

import axios from './../../axiosInstance.js';

const getUserSessionInfo = (set) => async ({ token }) => {

	try {

		// API POST-запрос

		const response = await axios.get('/api/account/info',{
			headers: { Authorization: `Bearer ${token}` }
		});

		// Устанавливаем статус авторизации в системе

		set({
			isAuthenticated: true, 
			props: response.data.props
		});

		// Возвращаем результат выполнения

		return {
			state: 'success'
		}

	} catch (err) {

		// Обработка ошибок - ответ на запрос не был получен

		if ( !err.response ) {
			return {
				state: 'error',
				errorCode: 'NoResponseError'
			}
		}

		// Обработка ошибок - если токен не существует



		// Обработка ошибок - неизвестная ошибка

		if ( err.response.status === 400 && err.response.data.errorCode === 'UndefinedError' ) {
			return {
				state: 'error',
				errorCode: err.response.data.errorCode
			}
		}

		//

		console.log(err);

	}

}

export default getUserSessionInfo;