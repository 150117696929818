import React, { useEffect } from 'react';

import useAuthStore from './../Store/Auth/authStore.js';

const CheckAuthWrapper = () => {

	const { getUserSessionInfo } = useAuthStore();

	useEffect(() => {

		// Получаем токен из хранилища

		const token = localStorage.getItem('token');

		// Если токена нет, прерываем

		if ( token === null ) { return; }

		// Авторизация в системе

		getUserSessionInfo({
			token
		});

	}, []);

	return null;

}

export default CheckAuthWrapper;