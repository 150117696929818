// Изменение пароля

import axios from './../../axiosInstance.js';

const changePassword = (set) => async (oldPassword, newPassword) => {

	try {

		// API POST-запрос

		const token = localStorage.getItem('token');

		const response = await axios.post('/api/account/changePassword', { oldPassword, newPassword }, {
			headers: {
				headers: { Authorization: `Bearer ${token}` }
			}
		});

		// Возвращаем результат выполнения

		return {
			state: 'success'
		}

	} catch (err) {

		// Обработка ошибок - ответ на запрос не был получен

		if ( err.response === undefined ) {
			return {
				state: 'error',
				errorCode: 'NoResponseError'
			}
		}

		// Обработка ошибок - неизвестная ошибка

		if ( err.response.status === 400 && err.response.data.errorCode === 'UndefinedError' ) {
			return {
				state: 'error',
				errorCode: err.response.data.errorCode
			}
		}

		//

		console.log(err);

	}

}

export default changePassword;