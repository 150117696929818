// Выход из системы

import axios from './../../axiosInstance.js';

const logout = (set) => async () => {

	try {

		// API POST-запрос

		const token = localStorage.getItem('token');

		const response = await axios.post('/api/account/logout', {}, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		// Удаляем токен авторизации из Local Storage

		localStorage.removeItem('token');

		// Устанавливаем статус авторизации в системе

		set({
			isAuthenticated: false,
			user: null
		});

		// Возвращаем результат выполнения

		return {
			state: 'success'
		}

	} catch (err) {

		// Обработка ошибок - ответ на запрос не был получен

		if ( err.response === undefined ) {
			return {
				state: 'error',
				errorCode: 'NoResponseError'
			}
		}

		// Обработка ошибок - неизвестная ошибка

		if ( err.response.status === 400 && err.response.data.errorCode === 'UndefinedError' ) {
			return {
				state: 'error',
				errorCode: err.response.data.errorCode
			}
		}

		//

		console.log(err);

	}
	
}

export default logout;